<template>
  <div></div>
</template>

<script>

export default {
  name: 'logout',
  mounted () {
    this.$store.dispatch('user/logout')
      .then(() => {
        this.$router.push('login')
          .then(() => {
            this.$store.dispatch('refreshSanctumToken')
          })
      })
  }
}

</script>
